module.exports = {
"Exchange": require('./Exchange.js').default,
"Experts": require('./Experts.js').default,
"OraclePrice": require('./OraclePrice.js').default,
"OracleTotSupply": require('./OracleTotSupply.js').default,
"OracleCircAmount": require('./OracleCircAmount.js').default,
"Index2SwapEthMarket": require('./Index2SwapEthMarket.js').default,
"IndexStorage": require('./IndexStorage.js').default,
"IndexTokens": require('./IndexTokens.js').default,
"ExpertPrices": require('./ExpertPrices.js').default,
"ExpertsRewards": require('./ExpertsRewards.js').default,
"IndexToken": require('./IndexToken.js').default,
"Faucet": require('./Faucet.js').default,
"IUniswapV2Factory": require('./IUniswapV2Factory.js').default,
"Lstorage": require('./Lstorage.js').default,
"IUniswapV2ERC20": require('./IUniswapV2ERC20.js').default,
"iIndex2Swap": require('./iIndex2Swap.js').default,
"SVTtst": require('./SVTtst.js').default,
"TokTst": require('./TokTst.js').default,
"UniswapV2ERC20": require('./UniswapV2ERC20.js').default,
"UniswapV2Factory": require('./UniswapV2Factory.js').default,
"IUniswapV2Pair": require('./IUniswapV2Pair.js').default,
"WETH": require('./WETH.js').default,
"iLstorage": require('./iLstorage.js').default,
"Context": require('./Context.js').default,
"Index2SwapEth": require('./Index2SwapEth.js').default,
"UniswapV2OracleLibrary": require('./UniswapV2OracleLibrary.js').default,
"IUniswapV2Router02": require('./IUniswapV2Router02.js').default,
"TransferHelper": require('./TransferHelper.js').default,
"SafeMath": require('./SafeMath.js').default,
"iOracleCircAmount": require('./iOracleCircAmount.js').default,
"Migrations": require('./Migrations.js').default,
"UQ112x112": require('./UQ112x112.js').default,
"IWETH": require('./IWETH.js').default,
"ERC20Capped": require('./ERC20Capped.js').default,
"Math": require('./Math.js').default,
"FixedPoint": require('./FixedPoint.js').default,
"iOracleTotSupply": require('./iOracleTotSupply.js').default,
"IERC20Uniswap": require('./IERC20Uniswap.js').default,
"ERC20": require('./ERC20.js').default,
"IndexFactorySvet": require('./IndexFactorySvet.js').default,
"iIndexStorage": require('./iIndexStorage.js').default,
"IUniswapV2Callee": require('./IUniswapV2Callee.js').default,
"iOraclePrice": require('./iOraclePrice.js').default,
"ERC20Detailed": require('./ERC20Detailed.js').default,
"IERC20": require('./IERC20.js').default,
"iExperts": require('./iExperts.js').default,
"UniswapV2Pair": require('./UniswapV2Pair.js').default,
"IndexFactory": require('./IndexFactory.js').default,
"SimpleStorage": require('./SimpleStorage.js').default,
"SafeMathUniswap": require('./SafeMathUniswap.js').default,
"iIndexToken": require('./iIndexToken.js').default,
"MinterRole": require('./MinterRole.js').default,
"IUniswapV2Router01": require('./IUniswapV2Router01.js').default,
"ERC20Mintable": require('./ERC20Mintable.js').default,
"Roles": require('./Roles.js').default,
"Index2Swap": require('./Index2Swap.js').default,
};