// import HomePage from '../../pages/HomePage';
import InvestmentsPage from '../../pages/InvestmentsPage/InvestmentsPage';
import paths from './paths';

const appRoutes = [
  // {
  //   path: paths.HOME,
  //   Component: HomePage,
  // },
  {
    path: paths.INVESTMENTS,
    Component: InvestmentsPage,
  },
];

export default appRoutes;
