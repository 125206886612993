import React from 'react';
import useStyles from './styles';

const ChangeSwapAssetsIcon = ({ className = null }) => {
  const classes = useStyles();

  return (
    <svg
      className={`${classes.root} ${className}`}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M18 5C18 2.51472 15.9853 0.5 13.5 0.5H13.3257C13.0495 0.5 12.8257 0.723858 12.8257 1C12.8257 1.27614 13.0495 1.5 13.3257 1.5H13.5C15.433 1.5 17 3.067 17 5L17 13.6972C17 15.6302 15.433 17.1972 13.5 17.1972L4.80275 17.1972C2.86976 17.1972 1.30275 15.6302 1.30275 13.6972L1.30275 5C1.30275 3.06701 2.86976 1.5 4.80275 1.5H4.97706C5.25321 1.5 5.47706 1.27614 5.47706 1C5.47706 0.723858 5.25321 0.5 4.97706 0.5H4.80275C2.31747 0.5 0.302752 2.51472 0.302752 5L0.302752 13.6972C0.302752 16.1825 2.31747 18.1972 4.80275 18.1972L13.5 18.1972C15.9853 18.1972 18 16.1825 18 13.6972L18 5ZM11.9746 9.27659C12.2008 9.11823 12.2558 8.80647 12.0975 8.58024C11.9391 8.35402 11.6274 8.299 11.4011 8.45736L9.61909 9.70481L9.61909 1.67432C9.61909 1.39817 9.39523 1.17432 9.11909 1.17432C8.84295 1.17432 8.61909 1.39817 8.61909 1.67432L8.61909 9.70481L6.83704 8.45736C6.61081 8.299 6.29905 8.35402 6.14069 8.58024C5.98233 8.80647 6.03735 9.11823 6.26357 9.27659L8.83235 11.0748L9.11909 11.2755L9.40582 11.0748L11.9746 9.27659Z"
      />
    </svg>
  );
};

export default ChangeSwapAssetsIcon;
