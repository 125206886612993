export const GET_INITIAL_DATA = 'GET_INITIAL_DATA'
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SET_SLIPPAGE = 'SET_SLIPPAGE'
export const SET_DELAY = 'SET_DELAY'
export const SET_SWAP_IN_AMOUNT = 'SET_SWAP_IN_AMOUNT'
export const SET_SWAP_MODE = 'SET_SWAP_MODE'
export const SET_SWAP_ASSET = 'SET_SWAP_ASSET'
export const CANCEL_SWAP_ASSETS = 'CANCEL_SWAP_ASSETS'
export const SET_NETWORK = 'SET_NETWORK'
export const SET_ERROR = 'SET_ERROR'
export const SET_GAS_PRICE = 'SET_GAS_PRICE'

export const SET_SWAP_START = 'SET_SWAP_START'
export const SET_SWAP_END = 'SET_SWAP_END'

export const RESET_SWAP_PROCESS_STATE = 'RESET_SWAP_PROCESS_STATE'
export const SET_SWAP_PROCESS_STATE = 'SET_SWAP_PROCESS_STATE'

export const GET_GAS_PRICE = 'GET_GAS_PRICE'

export const SET_CHAIN_DATA = 'SET_CHAIN_DATA'

export const SET_INVESTING = 'SET_INVESTING'
